// Author: Çağrı Erdem, 2024
// Description: Main script for the groove2tap study

// TODO: Before final publishing:
//
// METRONOME-LESS version!
// SON AYARLAR
// DATAYI KONTROL ET

// HER GROOVE'DAN SONRA YENIDEN SURVEY GELMELI
// SURVEY'I AIDA DISINDA BISI YAP???!!!

const listenImage = require('url:./../../assets/images/listen.png');
const tapImage = require('url:./../../assets/images/tap.png');
const rateImage = require('url:./../../assets/images/rate.png');

import { Metronome } from './utils/metronome.js';
import { collectTappingData } from './workerData.js';


function initializeApp() { //here, we wrapped everything for build

  const grooveVolumeSlider = document.getElementById('grooveVolume');
  const audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Initialize Web Audio API

  let gainNode = audioContext.createGain();
  let takeNumber; // Global variable to store the take number --this was necessary for build




  ////////////////////////////////////////////////////
  ////////// Metro init & Tap recordings /////////////
  ////////////////////////////////////////////////////

  let mainTempo = 100; 
  let recording = false;
  let newTappingMade = false; // Tracks if new tapping entries are made


  const metronome = new Metronome(mainTempo);

  const stepIds = [
    "introduction", "information", "consent", "pre_questionnaire", "instruction",
    "gr0", // Add gr0 here
    "gr1", "gr2", "gr3", "gr4", "gr5", "gr6", "gr7", "gr8", "gr9", "gr10", "gr11",
    "post_questionnaire"
  ];
  

  function initializeStep(stepId) {
    if (!stepId.startsWith('gr')) return;

    const tapControls = document.getElementById(`tapControls_${stepId}`);
    const countdownElement = document.getElementById(`countdownContainer_${stepId}`);
    const light = document.getElementById(`light_${stepId}`);
    const recIndicator = document.getElementById(`recIndicator_${stepId}`);
    const readyButton = document.getElementById(`readyToTapButton_${stepId}`);
    const stopButton = document.getElementById(`stopRecordingButton_${stepId}`);

    if (readyButton) {
        readyButton.addEventListener('click', () => {
            if (!recording) {
                resetRecordingState(); // Ensure state is reset
                recordCountdown(stepId, countdownElement, tapControls, recIndicator, light);
                takeNumber = getAndIncrementTakeNumber(stepId); // Move take number increment here
            }
        });

        stopButton.addEventListener('click', () => {
            if (recording) {
                stopRecording(recIndicator, stepId);
            }
        });
    }
}



  stepIds.forEach(initializeStep);


  // Global variable to store tap events
  let handleTapKeyDown; // Declare globally
  let tapEvents = [];
  let recordingStartedAt = null;
  let countdownToRecord = 4; // Countdown value in beats
  let firstTap = false; // Flag to check if the first tap has occurred


  function recordCountdown(stepId, countdownElement, tapControls, recIndicator, light) {
    const readyButton = document.getElementById(`readyToTapButton_${stepId}`);
    const stopButton = document.getElementById(`stopRecordingButton_${stepId}`);

    resetRecordingState(); // Reset state before starting new recording
    stopLoopPlayback();
    const audioPlayer = document.getElementById(`audioPlayer_${stepId}`);
    if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
    }

    readyButton.style.display = 'none';
    stopButton.style.display = 'inline-block'; // Show stop button

    // startCountdown(stepId, countdownElement, tapControls, recIndicator, light); //TODO: comment out to remove countdown

    // Remove countdown logic
    updateCountdown(countdownElement, 'Start!');

    // Directly start the recording process
    beginRecording(stepId, tapControls, recIndicator, light);

    stopButton.addEventListener('click', function handleStopButtonClick() {
        stopRecording(recIndicator, stepId);
    }, { once: true }); // Ensure the click event is only handled once

    readyButton.blur(); // Prevent re-triggering
  }




  function resetRecordingState() {
    console.log('Resetting recording state');
    tapEvents = []; // Reset the tap events array
    recordingStartedAt = null; // Reset the start time
    firstTap = false; // Reset the first tap flag
    recording = false; // Reset recording state
    detachTapListener(); // Detach any previous listeners
  }


  // function startCountdown(stepId, countdownElement, tapControls, recIndicator, light) {
  // // Commented out to remove the countdown feature
  //
  //   let countdown = countdownToRecord;
  //   updateCountdown(countdownElement, countdown);
  //   // Start visual countdown
  //   const countdownInterval = setInterval(() => {
  //     countdown--;
  //     updateCountdown(countdownElement, countdown > 0 ? countdown : 'Start!');

  //     if (countdown === 0) {
  //       clearInterval(countdownInterval);
  //       beginRecording(stepId, tapControls, recIndicator, light);
  //     }
  //   }, (60000 / mainTempo)); // Using the tempo for countdown speed
  // }


  function beginRecording(stepId, tapControls, recIndicator, light) {
    resetRecordingState(); // Reset all states at the beginning of each recording
    recIndicator.innerText = 'ON AIR!';
    recIndicator.classList.add('recording');
    tapControls.style.display = 'block';
    recording = true;

    attachTapListener(light);
  }


  function attachTapListener(light) {
    handleTapKeyDown = function (e) {
        if (e.key === ' ' && recording) {
            recordTapEvent();
            lightFeedback(light, true);

            setTimeout(() => {
                lightFeedback(light, false); // Schedule to turn off the light after 25 ms
            }, 25);
        }
    };

    detachTapListener(); // Remove any previous listener
    window.addEventListener('keydown', handleTapKeyDown); // Add new listener
  }

  function detachTapListener() {
    if (handleTapKeyDown) {
        window.removeEventListener('keydown', handleTapKeyDown);
    }
  }



  function recordTapEvent() {
    if (!recording) return; // Ensure we only record taps while recording

    const elapsedMs = Date.now() - recordingStartedAt;

    if (!firstTap) {
        recordingStartedAt = Date.now(); // Set the start time to the first tap time
        tapEvents.push(0); // The first tap is the point zero
        firstTap = true;
        // console.log('First tap recorded:', tapEvents);
    } else {
        tapEvents.push(Date.now() - recordingStartedAt); // Record the time since the first tap
        // console.log('Tap recorded:', tapEvents); //comment in/out for printing or not
    }
    newTappingMade = true; // Mark that a new tapping has been made
  }

  function stopRecording(recIndicator, stepId) {
    recording = false;
    detachTapListener();
    recIndicator.innerText = ' ';
    recIndicator.classList.remove('recording');
    console.log("Recording completed!");

    const readyButton = document.getElementById(`readyToTapButton_${stepId}`);
    const stopButton = document.getElementById(`stopRecordingButton_${stepId}`);
    stopButton.style.display = 'none';
    readyButton.style.display = 'inline-block';
    readyButton.disabled = true;
    
    const likertContainer = document.getElementById(`likertContainer_${stepId}`);
    likertContainer.style.display = 'block'; // Show the Likert scale slider (its container)
    
    const rateButton = document.getElementById('rate_btn');
    rateButton.style.display = 'none'; // Hide the blue rateButton/frame
    
    // Disable the 'Next' button
    document.getElementById('nextButton').disabled = true;
    
    // To handle rating-slider related stuff
    const likertSlider = document.getElementById(`likertSlider_${stepId}`);
    likertSlider.value = 3;
    const currentRatingContainer = document.getElementById(`currentRating_${stepId}`);
    currentRatingContainer.innerHTML = `Current Rating: <span id="ratingValue_${stepId}" style="display: none;">${likertSlider.value}</span>`;
    likertSlider.style.alignItems = 'center';
    likertSlider.style.justifyContent = 'center';
    likertSlider.style.display = 'flex';
    likertSlider.style.flexDirection = 'column';
    
    const ratingValueSpan = document.getElementById(`ratingValue_${stepId}`);
    ratingValueSpan.style.fontSize = '20px';
    ratingValueSpan.style.color = "rgb(226, 43, 198)";
    
    likertSlider.oninput = function() { // Listen for changes on the slider 
      ratingValueSpan.style.display = 'inline'; // Show the rating value when the slider is interacted with
      ratingValueSpan.textContent = this.value;
    };
    
    // Handle mouseup and touchend events to capture the final value
    likertSlider.onmouseup = likertSlider.ontouchend = function() {
      ratingValueSpan.style.display = 'inline'; // Ensure the rating value is shown on mouseup/touchend
      ratingValueSpan.textContent = likertSlider.value;
    
      readyButton.disabled = false;
      document.getElementById('nextButton').disabled = false;
      console.log(`Participant ${getParticipantIdSync()} set their rating to ${likertSlider.value}`);

      console.log("Recorded tap events:", tapEvents); // Print the recorded array for debugging

      // Show the survey modal
      showSurveyModal(stepId);
    
      // if (stepId !== 'gr0') { // Finally send the data to the server
      //   sendArrayToServer(tapEvents, stepId, getParticipantIdSync(), likertSlider.value);
      //   resetRecordingState(); // Reset the tap events array and first tap flag
      // }
    };
  }

  function updateCountdown(countdownElement, text) {
    // countdownElement.textContent = text;
    countdownElement.textContent = ''; // Clear the countdown text to remove the countdown display
  }

  function lightFeedback(light, on) {
    light.classList.toggle('light-on', on);
    light.classList.toggle('light-off', !on);
  }
    





  /////////////////////////////////////////
  ///// Participant data handling ////////
  ////////////////////////////////////////

  let httpIp = `127.0.0.1`;
  let httpPort = `5003`;

  async function getParticipantId() {
    // function to generate and verify a unique participant ID
    let participantId = localStorage.getItem('participantId');
    if (!participantId) {
      let isUnique = false;
      while (!isUnique) {
        participantId = `P${Math.random().toString(36).substr(2, 3)}`; // generate

        // const response = await fetch(`http://${httpIp}:${httpPort}/check_id`, {
        const response = await fetch(`https://tapthegroove.com/check_id`, { //check whether its unique in the database or not
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ participantId })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        isUnique = data.isUnique;
      }

      localStorage.setItem('participantId', participantId); // Store the confirmed unique participantId
      document.getElementById('participantIdDisplay').textContent = participantId; // Show the participant ID on the main page corner
    }
    return participantId;
  }

  let fetchParticipantIdPromise = null; // This will hold the promise

  function initializeParticipantId() {
    if (!fetchParticipantIdPromise) {
      fetchParticipantIdPromise = getParticipantId();
    }
    return fetchParticipantIdPromise;
  }

  function getParticipantIdSync() {
    initializeParticipantId();
    let participantId = localStorage.getItem('participantId');
    if (!participantId) {
      console.error("Participant ID not initialized yet.");
      return null;
    }
    return participantId;
  }

  function getAndIncrementTakeNumber(grooveId) {
    // Function to get and increment take number
    const currentGrooveData = localStorage.getItem('grooveData'); // Retrieve current groove and take number information
    const grooveData = currentGrooveData ? JSON.parse(currentGrooveData) : {};

    if (grooveData.grooveId === grooveId && grooveData.participantId === getParticipantIdSync()) { // Check if the current grooveId matches the stored one and if it's the same participant
      grooveData.takeNumber = (grooveData.takeNumber || 0) + 1;
    } else {
      grooveData.grooveId = grooveId;
      grooveData.takeNumber = 1;
      grooveData.participantId = getParticipantIdSync(); // Ensure the participantId is stored with the groove data
    }

    localStorage.setItem('grooveData', JSON.stringify(grooveData)); // Store updated groove data
    const takeNumberElement = document.getElementById('takeNumber');
    if (takeNumberElement) {
      takeNumberElement.textContent = grooveData.takeNumber; // Update the take number display
    }

    return grooveData.takeNumber;
  }

  async function sendArrayToServer(array, stepId, participantId, rating, easeRating, surveyResponse) {
    // TODO: add the survey response to the data object
    const user_data = await collectTappingData(participantId, stepId, takeNumber, array, rating, easeRating, surveyResponse);
    // const data_url = `http://${httpIp}:${httpPort}/submit_data`; //comment out when deploying
    const data_url =`https://tapthegroove.com/submit_data`; //comment out when testing locally
    console.log("Sending request to:", data_url);

    try {
      const response = await fetch(data_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user_data)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Server response:', responseData);
    } catch (error) {
      console.error('Error sending data to server:', error);
    }
  }

  function resetSessionData() {
    localStorage.removeItem('participantId');
    localStorage.removeItem('grooveData');
    getParticipantId(); //generate a new p ID for new session
    grooveCounter = 0; // Reset groove counter
  }

  let audioSrc = '';
  const stepAudioMap = { //grooves' paths 
    "gr0": require('url:./../../assets/sounds/test_groove.wav'),
    "gr1": require('url:./../../assets/sounds/BonJ_1_4-6_MT.wav'),
    "gr2": require('url:./../../assets/sounds/ChaL_4_4-6_MT.wav'),
    "gr3": require('url:./../../assets/sounds/GadJ_5_4-6_MT.wav'),
    "gr4": require('url:./../../assets/sounds/Gar_D3_2-4_MT.wav'),
    "gr5": require('url:./../../assets/sounds/GroD_3_2-4_MT.wav'),
    "gr6": require('url:./../../assets/sounds/JacA_5_2-4_MT.wav'),
    "gr7": require('url:./../../assets/sounds/NelP_2_4-6_MT.wav'),
    "gr8": require('url:./../../assets/sounds/PaiI_4_0-2_MT.wav'),
    "gr9": require('url:./../../assets/sounds/PorM_4_2-4_MT.wav'),
    "gr10": require('url:./../../assets/sounds/SteB_4_2-4_MT.wav'),
    "gr11": require('url:./../../assets/sounds/TayR_3_0-2_MT.wav')
  };

  const stepTempoMap = { //grooves' tempo info
    "gr0": 91.8, // Default tempo for the test groove
    "gr1": 92, "gr2": 117, "gr3": 117, "gr4": 108, "gr5": 120,
    "gr6": 110, "gr7": 103, "gr8": 113, "gr9": 102, "gr10": 93, "gr11": 110
  };

  function playAudioTrack(stepId) {
    if (!audioSrc) {
      console.error('No audio source set for step: ' + stepId);
      return;
    }

    // Use the stepId to target the specific audioPlayer and audioProgressBar for this step
    const audioPlayer = document.getElementById(`audioPlayer_${stepId}`);
    const audioProgressBar = document.getElementById(`audioProgressBar_${stepId}`);
    const savedGrooveVolume = localStorage.getItem('grooveVolume');
    console.log(`Playback volume: ${savedGrooveVolume}`);

    if (!audioPlayer || !audioProgressBar) {
      console.error('Audio player or progress bar not found for step: ' + stepId);
      return;
    }

    audioPlayer.src = audioSrc;
    audioPlayer.load();
    audioPlayer.volume = savedGrooveVolume ? savedGrooveVolume : 1.0; // Use saved volume or default to 0.7
    audioPlayer.play();

    grooveVolumeSlider.addEventListener('input', function(event) {
      const newVolume = parseFloat(event.target.value);
      localStorage.setItem('grooveVolume', newVolume);
      audioPlayer.volume = newVolume;
    });

    audioPlayer.ontimeupdate = function() {
      if (audioPlayer.duration > 0) {
        const percentage = (audioPlayer.currentTime / audioPlayer.duration) * 100;
        audioProgressBar.value = percentage;
      }
    };
  }

  let currentGroove = null;
  document.querySelectorAll('.playButton').forEach(button => {
    button.addEventListener('click', function() {
      const currentStepId = stepIds[currentStep - 1];
      if (stepAudioMap[currentStepId]) {
        currentGroove = currentStepId;
        playAudioTrack(currentStepId);
      } else {
        console.error('No audio source set for current step: ' + currentStepId);
      }
    });
  });


  ////////////////////////////////////////////////////
  ////////////////// GUI Controls ////////////////////
  ////////////////////////////////////////////////////

  document.getElementById('nextButton').addEventListener('click', () => { 
    // Next button event listener to navigate through steps
    if (currentStep < totalSteps) {
      goToStep(currentStep + 1);
    } else {
      console.log('Study completed');
    }
  });


  document.addEventListener('DOMContentLoaded', () => {
    grooveCounter = 0; // Reset groove counter
    //Apply Volume Levels on Page Load and Step Changes
    const savedGrooveVolume = localStorage.getItem('grooveVolume');
    if (savedGrooveVolume !== null) {
      grooveVolumeSlider.value = savedGrooveVolume;
    }
  });


  /////////////////////////////////////////////////////////////
  ////////////////// Shuffled Progres steps ///////////////////
  ////////////////////////////////////////////////////////////

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  function shuffleGrSteps(stepIds) {
    const start = 6; // Adjust start index to exclude gr0
    const end = 17; // Adjust end index to include all gr steps
    const grSteps = stepIds.slice(start, end);
    const shuffledGrSteps = shuffleArray(grSteps);
    return [...stepIds.slice(0, start), ...shuffledGrSteps, ...stepIds.slice(end)];
  }
  

  let shuffledStepIds = [];
  document.addEventListener('DOMContentLoaded', function() {
    const stepsContainer = document.getElementById('stepsContainer');
    shuffledStepIds = shuffleGrSteps(stepIds);
    console.log('Shuffled step IDs:', shuffledStepIds);

    // TODO: Add during-study questionnaire after each groove tapping step
  
    // Create step element for gr0 separately
    const gr0Div = document.createElement('div');
    gr0Div.id = 'gr0';
    gr0Div.className = 'step study-step';
    gr0Div.style.display = 'none';
    gr0Div.innerHTML = `
      <div class="interfaceContainer">
          <div class="box" id="listen-box">
              <img src="${listenImage}" alt="Listen" class="icon">
              <div class="audioPlayerContainer">
                <audio class="audioPlayer" id="audioPlayer_gr0" controls hidden></audio>
                    <label class="loopToggleContainer">
                      <input type="checkbox" id="loopToggle_gr0" class="loopToggle">
                      Loop the playback
                    </label>
              </div>
          </div>
          <div class="box" id="tap-box">
              <img src="${tapImage}" alt="Tap" class="icon">
                <div class="buttonContainer">
                  <button id="readyToTapButton_gr0" class="readyButton">Tap the Groove</button>
                  <button id="stopRecordingButton_gr0" class="readyButton" style="display:none;">Stop the recording</button>
                </div>
              <div id="countdown-container">
                  <div class="countdown-container" id="countdownContainer_gr0"></div>
                  <div class="tapControls" id="tapControls_gr0">
                      <div class="recIndicator" id="recIndicator_gr0">REC OFF</div>
                      <label class="label-secondary" for="tapTempo_gr0">Tapped rhythms:</label>
                      <div class="light light-base light-off" id="light_gr0"></div>
                  </div>
              </div>
          </div>
          <div class="box" id="rate-box">
              <img src="${rateImage}" alt="Rate" class="icon">
              <button id="rate_btn" class="rate-tap-btn"  title="The slider for rating will appear after you submit your tappings">Rate Your Tap</button>
              <div id="likert-container">
                  <div class="likert-container" id="likertContainer_gr0" style="display: none;">
                      <div id="takeNumberDisplay">Take Number: <span id="takeNumber"></span></div>
                      <label for="likertSlider_gr0">Rate your performance:</label>
                      <input type="range" id="likertSlider_gr0" min="1" max="5" value="3" class="likert-slider">
                      <span id="currentRating_gr0">Current Rating: 3</span>
                  </div>
              </div>
          </div>
      </div>
    `;
    stepsContainer.appendChild(gr0Div);
    initializeStep('gr0'); // Initialize gr0 step
  
    // Create step elements for shuffled "gr" steps
    for (let i = 6; i <= 16; i++) { // Adjust the loop range to skip gr0
      const stepId = shuffledStepIds[i];
      const stepDiv = document.createElement('div');
  
      stepDiv.id = stepId;
      stepDiv.className = 'step study-step';
      stepDiv.style.display = 'none';
      stepDiv.innerHTML = `
        <div class="interfaceContainer">
            <div class="box" id="listen-box">
                <img src="${listenImage}" alt="Listen" class="icon">
                <div class="audioPlayerContainer">
                  <audio class="audioPlayer" id="audioPlayer_${stepId}" controls hidden></audio>
                      <label class="loopToggleContainer">
                        <input type="checkbox" id="loopToggle_${stepId}" class="loopToggle">
                        Loop the playback
                      </label>
                </div>
            </div>
            <div class="box" id="tap-box">
                <img src="${tapImage}" alt="Tap" class="icon">
                <div class="buttonContainer">
                  <button id="readyToTapButton_${stepId}" class="readyButton">Tap the Groove</button>
                  <button id="stopRecordingButton_${stepId}" class="readyButton" style="display:none;">Stop the recording</button>
                </div>
                <div id="countdown-container">
                    <div class="countdown-container" id="countdownContainer_${stepId}"></div>
                    <div class="tapControls" id="tapControls_${stepId}">
                        <div class="recIndicator" id="recIndicator_${stepId}">REC OFF</div>
                        <label class="label-secondary" for="tapTempo_${stepId}">Tapped rhythms:</label>
                        <div class="light light-base light-off" id="light_${stepId}"></div>
                    </div>
                </div>
            </div>
            <div class="box" id="rate-box">
                <img src="${rateImage}" alt="Rate" class="icon">
                <button id="rate_btn" class="rate-tap-btn" title="The slider for rating will appear after you submit your tappings">Rate Your Tap</button>
                <div id="likert-container">
                    <div class="likert-container" id="likertContainer_${stepId}" style="display: none;">
                        <div id="takeNumberDisplay_${stepId}">Take Number: <span id="takeNumber_${stepId}"></span></div>
                        <label for="likertSlider_${stepId}">Rate your performance:</label>
                        <input type="range" id="likertSlider_${stepId}" min="1" max="5" value="3" class="likert-slider">
                        <span id="currentRating_${stepId}">Current Rating: 3</span>
                    </div>
                </div>
            </div>
        </div>
      `;
  
      stepsContainer.appendChild(stepDiv);
      initializeStep(stepId);
    }
  });

  

  let currentStep = 1;
  let grooveCounter = 0;
  const totalSteps = stepIds.length; // Dynamically determine the total number of steps
  const consentStepNumber = 3;
  const formStepNumber = 4;

  function goToStep(stepNumber) {
    console.log('Requested step number:', stepNumber);
    if (stepNumber > stepIds.length) {
      console.error(`Step ${stepNumber} is out of bounds.`);
      return;
    }

    if (stepNumber === 1) {
      resetSessionData();
    }

    currentStep = stepNumber;   // Update the current step number
    document.querySelectorAll('.step').forEach(step => step.style.display = 'none'); // Hide all steps and show the current one
    const stepId = shuffledStepIds[stepNumber - 1];
    console.log('Going to step ID:', stepId);

    const stepElement = document.getElementById(stepId);
    if (!stepElement) {
      console.error(`Step element for ${stepId} not found.`);
      return;
    }
    stepElement.style.display = 'block';


    // Update the header text
    const grooveHeader = document.getElementById('grooveHeader');
    if (stepId === 'gr0') {
      grooveHeader.textContent = 'Test Groove';
    } else if (stepId.startsWith('gr')) {
      grooveCounter++;
      grooveHeader.textContent = `Groove #${grooveCounter}`;
    }

    if (stepTempoMap[stepId]) { // Update the main tempo based on the particular groove excerpt in the current step
      mainTempo = stepTempoMap[stepId];
      metronome.tempo = mainTempo;
      console.log(`Set tempo to ${mainTempo} for groove ${stepId}`);
    }

    //TODO: Uncomment before deploy!
    // if (stepNumber === consentStepNumber) { // Handle consent form logic (disable the progress unntil the consent form is checked)
    //   document.getElementById('nextButton').disabled = true;
    //   document.getElementById('consentCheckbox').addEventListener('change', function() {
    //     document.getElementById('nextButton').disabled = !this.checked;
    //   });
    // } else if (stepNumber === formStepNumber) { // Check if the form submission step and handle the button state accordingly
    //   document.getElementById('nextButton').disabled = true; //TODO: enable it back after testing

    // } else {
    //   document.getElementById('nextButton').disabled = false;
    // }

    if (stepNumber === totalSteps) {
      document.getElementById('nextButton').style.display = "none";
    }

    audioSrc = stepAudioMap[stepId] || ''; // Update the global audio source variable for the current step
    if (audioSrc) {
      console.log(`Audio source for ${stepId}: ${audioSrc}`);
    }

    manageAudioControlsForStep(stepId, stepElement);
    updateProgress(stepNumber);
  }

  function manageAudioControlsForStep(stepId, stepElement) {
    const audioPlayerContainer = stepElement.querySelector('.audioPlayerContainer'); // Identify which audioPlayerContainer within the current step
    const topContainer = document.getElementById("top");

    if (["introduction", "information", "instruction", "consent", "pre_questionnaire", "post_questionnaire", "thank_you"].indexOf(stepId) === -1) {
      topContainer.style.display = "block";
    } else {
      topContainer.style.display = "none";
    }

    const listenBox = stepElement.querySelector('#listen-box .icon');
    const tapBox = stepElement.querySelector('#tap-box .icon');
    const rateBox = stepElement.querySelector('#rate-box .icon');


    if (listenBox) {
      listenBox.src = listenImage;
      listenBox.alt = 'Listen';
    }

    if (tapBox) {
      tapBox.src = tapImage;
      tapBox.alt = 'Tap';
    }

    if (rateBox) {
      rateBox.src = rateImage;
      rateBox.alt = 'Rate';
    }


    if (audioPlayerContainer) {
      const existingButton = audioPlayerContainer.querySelector('.playButton');
      const existingProgressBar = audioPlayerContainer.querySelector('.audioProgressBar');
      if (existingButton) existingButton.remove();
      if (existingProgressBar) existingProgressBar.remove();

      if (audioSrc) { // Add new audio controls if the current step has associated audio
        document.getElementById('nextButton').disabled = true;

        const playButton = document.createElement('button');
        playButton.id = 'playButton';
        playButton.textContent = 'Listen to the Groove';
        playButton.className = 'playButton';
        playButton.addEventListener('click', function() {
          playAudioTrack(stepId);
          this.blur();
        });
        audioPlayerContainer.appendChild(playButton);

        const audioProgressBar = document.createElement('progress');
        audioProgressBar.className = 'audioProgressBar';
        audioProgressBar.id = `audioProgressBar_${stepId}`;
        audioProgressBar.value = 0;
        audioProgressBar.max = 100;
        audioPlayerContainer.appendChild(audioProgressBar);

        const audioPlayer = audioPlayerContainer.querySelector('.audioPlayer') || document.createElement('audio');
        audioPlayer.className = 'audioPlayer';
        audioPlayer.id = `audioPlayer_${stepId}`;
        audioPlayerContainer.appendChild(audioPlayer);

        audioPlayer.ontimeupdate = function() {
          if (audioPlayer.duration > 0) {
            const percentage = (audioPlayer.currentTime / audioPlayer.duration) * 100;
            audioProgressBar.value = percentage;
          }
        };
      }
    } else {
      console.log(`Audio player container not found in the ${stepId} step.`);
    }

    // Ensure the loop stops and playback is stopped when the readyButton is clicked
    document.getElementById(`nextButton`).addEventListener('click', function() {
      stopLoopPlayback();
      const audioPlayer = document.getElementById(`audioPlayer_${stepId}`);
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
      }
    });
  }

  function updateProgress(stepNumber) {
    const progressBar = document.getElementById('studyProgressBar');
    const progressText = document.getElementById('progressText');
    progressBar.value = stepNumber;
    progressText.textContent = `Part ${stepNumber} of ${totalSteps}`;
  }


  /////////////////////////
  //// Loop Playback //////
  /////////////////////////

let isLooping = false;
let currentAudioPlayer = null;

function handleLoopPlayback() {
  if (isLooping && currentAudioPlayer) {
    currentAudioPlayer.currentTime = 0;
    currentAudioPlayer.play();
  }
}

function toggleLoopPlayback(stepId) {
  const loopToggle = document.getElementById(`loopToggle_${stepId}`);
  const audioPlayer = document.getElementById(`audioPlayer_${stepId}`);
  if (!audioPlayer || !loopToggle) return;

  if (loopToggle.checked) {
    isLooping = true;
    audioPlayer.loop = true;
    audioPlayer.addEventListener('ended', handleLoopPlayback);
  } else {
    isLooping = false;
    audioPlayer.loop = false;
    audioPlayer.removeEventListener('ended', handleLoopPlayback);
  }

  currentAudioPlayer = audioPlayer; // Track the current audio player for stopping later
}

function stopLoopPlayback() {
  isLooping = false;
  if (currentAudioPlayer) {
    currentAudioPlayer.loop = false;
    currentAudioPlayer.removeEventListener('ended', handleLoopPlayback);
  }

  // Ensure all loop toggle checkboxes are unchecked
  document.querySelectorAll('.loopToggle').forEach(toggle => {
    toggle.checked = false;
  });
}

document.addEventListener('DOMContentLoaded', function() {
  const stepsContainer = document.getElementById('stepsContainer');

  // Handle loop toggle change
  stepsContainer.addEventListener('change', function(event) {
    if (event.target && event.target.classList.contains('loopToggle')) {
      const stepId = event.target.id.split('_')[1];
      toggleLoopPlayback(stepId);
    }
  });

  // Handle the readyButton click to stop the loop and playback
  stepsContainer.addEventListener('click', function(event) {
    if (event.target && event.target.classList.contains('readyButton')) {
      stopLoopPlayback();
      const stepId = event.target.id.split('_')[1];
      const audioPlayer = document.getElementById(`audioPlayer_${stepId}`);
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.currentTime = 0;
      }
    }
  });
});

  
  


  /////////////////////////
  //// Survey handling ////
  /////////////////////////

  document.addEventListener('DOMContentLoaded', function() {

    const urlParams = new URLSearchParams(window.location.search);
    const formSubmitted = urlParams.get('formSubmitted');

    if (formSubmitted === 'true') {
      window.history.replaceState(null, null, window.location.pathname);
      document.getElementById('nextButton').disabled = false;
      goToStep(5);
      let participantId = localStorage.getItem('participantId');
      document.getElementById('participantIdDisplay').textContent = participantId;
    } else {
      goToStep(1);
    }
  });



  document.addEventListener('DOMContentLoaded', function() {
    let modal = document.getElementById("surveyModal");

    if (!modal) {
        console.error("Survey modal not found.");
        return;
    }

    // Ensure the modal is closed only when the user submits the survey
    window.onclick = function(event) {
        if (event.target === modal) {
            console.warn("Modal must not be closed by clicking outside.");
        }
    };
});

// Function to display the survey modal
function showSurveyModal(stepId) {
  let modal = document.getElementById("surveyModal");
    resetSurveyState(); // Reset the state of the question
    modal.style.display = "block";
    modal.setAttribute('data-current-step', stepId);  // Pass the current step ID to the modal for use when submitting
    document.getElementById('nextButton').disabled = true;  // Disable the Next button while modal is open
}

document.addEventListener('DOMContentLoaded', function() {
  const grooveEaseSlider = document.getElementById("grooveEaseSlider");
  const currentEaseValue = document.getElementById("currentEaseValue");

  if (!grooveEaseSlider || !currentEaseValue) {
      console.error("Groove ease slider or display element not found.");
      return;
  }

  // Dynamically adjust the `currentEaseValue` display when the slider is moved
  grooveEaseSlider.oninput = function() {
      currentEaseValue.textContent = this.value;
  };

  // Update the submit button logic to include the groove ease response
  const submitBtn = document.getElementById("submitSurveyBtn");
  if (submitBtn) {
      submitBtn.addEventListener('click', function() {
          submitSurvey();
      });
  } else {
      console.error("Submit button not found.");
  }


  // Manage other elements such as the radio button for 'Other'
  document.querySelectorAll('input[name="memory"]').forEach(input => {
      input.addEventListener('change', function() {
          if (this.value === "Other") {
              document.getElementById("otherText").style.display = 'block';
          } else {
              document.getElementById("otherText").style.display = 'none';
          }
      });
  });
});



//TODO: modify var into let or const
function submitSurvey() {
  const modal = document.getElementById("surveyModal");
  const selectedMemory = document.querySelector('input[name="memory"]:checked');
  const grooveEaseResponse = grooveEaseSlider.value; // Get the ease slider value
  let tapDescription = '';

  if (selectedMemory) {
      if (selectedMemory.value === 'Other') {
          tapDescription = document.getElementById("otherText").value.trim();
      } else {
          tapDescription = selectedMemory.value;
      }
  } else {
      console.warn("No memory option selected.");
  }

  console.log("Survey Response:", {
      grooveEaseResponse,
      tapDescription,
  });

  modal.style.display = "none";
  document.getElementById('nextButton').disabled = false; // Re-enable the Next button

  // Handling data submission
  const stepId = modal.getAttribute('data-current-step'); // Ensure this attribute is set when the modal is shown
  if (stepId !== 'gr0') {
      const likertValue = document.getElementById(`likertSlider_${stepId}`).value;
      sendArrayToServer(tapEvents, stepId, getParticipantIdSync(), likertValue, grooveEaseResponse, tapDescription);
  }
}




document.querySelectorAll('input[name="memory"]').forEach(input => {
  input.addEventListener('change', function() {
      if (this.value === "Other") {
          document.getElementById("otherText").style.display = 'block';
      } else {
          document.getElementById("otherText").style.display = 'none';
      }
  });
});



function resetSurveyState() {
  // Clear all radio button selections
  const memoryInputs = document.querySelectorAll('input[name="memory"]');
  memoryInputs.forEach(input => {
      input.checked = false; // Uncheck all options
  });

  // Hide the "Other" text field and clear its value
  const otherTextInput = document.getElementById("otherText");
  if (otherTextInput) {
      otherTextInput.style.display = 'none'; // Hide the field
      otherTextInput.value = ''; // Clear any previous text
  }
}


  ////////////////////////////////////////////
  ////// Stop Videos on Next Button //////////
  ///////////////////////////////////////////
  
  const stopVideos = () => {
    document.querySelectorAll('iframe').forEach(v => { v.src = v.src });
    document.querySelectorAll('video').forEach(v => { v.pause() });
  };

  document.addEventListener('DOMContentLoaded', function() {
    const nextButton = document.getElementById('nextButton');
    nextButton.addEventListener('click', function() {
      stopVideos();
    });
  });

  // No need to call goToStep(1) here because it's already handled above
}

// start the app!
initializeApp();
